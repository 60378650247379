// Object Master
var Master = function () {
    const overlay = {
        $el: null, //obj jQuery de l'overlay

        // Initialise la classe
        init: function () {
            this.$el = $('<div/>').prop('id', 'modal-overlay').addClass("overlay").appendTo($('body'));
        },

        //fadeIn de l'overlay
        fadeIn: function () {
            this.$el.addClass('visible');
        },

        //fadeOut de l'overlay
        fadeOut: function (callback) {
            this.$el.removeClass('visible');
            if (callback) callback.call();
        },

        //écouteur d'un click sur l'élement
        click: function (callback) {
            this.$el.click(callback);
        }
    };

    this.start = function () {
        $('[data-toggle="sidebar"]').click(function () {
            $($(this).data("target")).toggleClass('toggled');
        });

        $('.description-fiche dt').click(function () {
            $(this).toggleClass('open');
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('.t-item .openable').click(function () {
            $(this).toggleClass('open');
        });

        $('.openable-item').click(function (event) {
            $(event.target).closest(".section-openable").find(".t-item-title.openable").addClass('open');
        });

        $('a.link-top-card').click(function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
            }
        });

        $('.top-card-titre').click(function (e) {
            if ($(window).width() < 992) {
                $($(this)).toggleClass('active');
            }
        });

        $(".all-sections .secteur").on("click", function (e) {
            if ($(window).width() >= 992) {
                e.preventDefault();
                var box = $(this);
                var hash = "secteur-" + box.attr('id');
                addHash(hash);
                $(".all-sections .cadre-secteur").each(function () {
                    $(this).removeClass("hidden");
                });
                box.parent().animate({opacity: 0}, 1000, function () {
                    $(this).addClass("hidden");
                    $(this).css("opacity", "1");
                });
                var div = $('<div>').css({
                    "background": "#e3e3e3",
                    "z-index": 10,
                    "position": "absolute",
                    "height": $(this).height(),
                    "width": $(this).width(),
                    "top": $(this).offset().top,
                    "left": $(this).offset().left,
                });
                div.appendTo(".all-sections");

                var header = $("#section-header-box");
                header.css("display", "block");
                header.animate({height: $(this).height(), width: "100%"}, 500, function () {
                });
                div.animate({
                        top: header.offset().top,
                        left: header.offset().left,
                    },
                    500, function () {
                        div.animate({height: header.height(), width: header.width()}, 400, function () {
                            header.css("opacity", "0");
                            header.html(box.html());
                            $("#section-header-box img").attr('src', $("#section-header-box img").data('high'));
                            div.fadeOut(200, function () {
                                this.remove()
                            });
                            header.css("height", "auto");
                            header.animate({"opacity": "1"}, 700);
                        });
                    });
                $('html, body').animate({scrollTop: $("#section-header-box").offset().top - 100}, '750');
            }
        });

        $(window).load(function () {
            const target = $(window.location.hash.toString());
            if (target.length) {
                if (target.prop('id') === 'demande-doc-form') {
                    focusForm();
                } else {
                    target.addClass("open");
                }
            }

            $('body').on('click', '.btn.btn-more', function (event) {
                event.preventDefault();
                focusForm();
            })

            addGclid();
        });

        var addHash = function (hash) {
            document.location.hash = "#" + hash;
        };

        var offsetPSP = -(new Date().getTimezoneOffset() / 60);
        $("input[name='_timezoneOffset']").val(offsetPSP);

        $('#demande-documentation').on('submit', function (e) {
            e.preventDefault();
            var form = $(this);
            loader(form);
            var inputs = form.serialize();
            $.post(form.attr('action'), inputs, function (response) {
                if (response.status == "success") {
                    form.append("<iframe src=" + response.content.conversion_url + " class=\"hidden\"></iframe>");
                    form.append(createSuccessHtml(form.attr('id'), "Votre formulaire a été envoyé avec succès pour la formation : <br><span id='form-nomformation'>" + response.content.nom_formation + "</span>"));
                    deleteError("#demande-documentation");
                    setTimeout(function () {
                        $("#success-submit-" + form.attr('id')).remove();
                    }, 5000);

                } else if (response.status == "error") {
                    deleteError("#demande-documentation");
                    var champs = response.content.form.champs;
                    for (var i in champs) {
                        if (champs[i].error != undefined) {
                            $("[name=" + champs[i].name + "]").parent().append(createErrorHtml(champs[i].error));
                        }
                    }
                }
                loader(form, "hide");
            }).fail(function (xhr) {
                deleteError("#demande-documentation");
                loader(form, "hide");
                form.append(createErrorHtml("Erreur lors de l'envoi du formulaire, veuillez contacter l'administrateur."));
            });
        });

        const focusForm = function () {
            const form = $('#demande-doc-form');
            $('html, body').animate(
                {scrollTop: form.offset().top - 30}, 500
            );

            form.addClass('highlight');
            overlay.fadeIn();

            overlay.click(function () {
                overlay.fadeOut();
                form.removeClass('highlight');
            });
        }

        var loader = function (form, action, msg) {
            action = (typeof action != 'undefined') ? action : "show";
            msg = (typeof msg != 'undefined') ? msg : "Votre requête est en cours d'acheminement";
            var load = form.find("#loader-" + form.attr('id'));
            if (action == "show") {
                if (load.length == 0) {
                    var element = createLoaderHtml(form.attr('id'), msg);
                    form.append(element);
                }
            } else if (action == "hide") {
                $("#loader-" + form.attr('id')).remove();
            }
        };

        var deleteError = function (parent) {
            $(parent).find('.error').remove();
            $(parent).find('.error-cross').remove();
        };

        var createLoaderHtml = function (id, msg) {
            return "<div class=\"loader form-process\" id=\"loader-" + id + "\"><div class=\"content\"><i class=\"icon-cycle spinner\"></i><p>" + msg + "</p></div></div>";
        };

        var createSuccessHtml = function (id, msg) {
            return "<div class=\"success-submit form-process\" id=\"success-submit-" + id + "\"><div class=\"content\"><i class=\"icon-check\"></i><p>" + msg + "</p></div></div>";
        };

        var createErrorHtml = function (msg) {
            return "<span class=\"error-cross\"><i class=\"icon-cross\"></i></span> <span class=\"error\">" + msg + "</span>";
        };

        /*
        *
        * BLOC GESTION GCLID
        *
        */
        var getParam = function (p) {
            var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        }

        var getExpiryRecord = function (value) {
            var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // Période d'expiration correspondant à 90 jours en millisecondes

            var expiryDate = new Date().getTime() + expiryPeriod;
            return {
                value: value,
                expiryDate: expiryDate
            };
        }

        var addGclid = function () {
            var gclidParam = getParam('gclid');
            var gclidFormFields = ['gclid']; // Ajouter ici tous les ID possibles pour le champ GCLID du formulaire
            var gclidRecord = null;
            var currGclidFormField;

            var gclsrcParam = getParam('gclsrc');
            var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

            gclidFormFields.forEach(function (field) {
                if (document.querySelector('input[name=' + field + ']')) {
                    currGclidFormField = document.querySelector('input[name=' + field + ']');
                }
            });

            if (gclidParam && isGclsrcValid) {
                gclidRecord = getExpiryRecord(gclidParam);
                localStorage.setItem('gclid', JSON.stringify(gclidRecord));
            }

            var gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
            var isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

            if (currGclidFormField && isGclidValid) {
                currGclidFormField.value = gclid.value;
            }
        }

        $('body').on('click', '.avertissement-cookie .ok', function () {
            $('.avertissement-cookie').fadeOut('fast');
        });

        $('#btn-cookie-cnil').on('click', function () {
            document.cookie = "advert-cookie=accept";
        });

        overlay.init();
    };
};
var masterClass = new Master();
